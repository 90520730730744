import { useEffect, type FC, type PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCorrectoApi, useUser } from '../../contexts'
import { useCommonApiErrorMessage } from '../../hooks'

export const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const correctoApi = useCorrectoApi()
  const { setUser, logout } = useUser()
  const { t } = useTranslation()
  const { checkForCommonErrorMessage } = useCommonApiErrorMessage()

  const getUser = () => {
    correctoApi.getUser().then(response => {
      if (response.ok) {
        setUser(response.body)
        correctoApi.sendTokenToExtension(response.body)
      } else {
        checkForCommonErrorMessage(
          response,
          t('Se ha producido un error al obtener la información de usuario'),
        )
      }
    })
  }

  useEffect(() => {
    if (!correctoApi.isAuthenticated()) {
      logout()
      navigate('/login')
    } else {
      getUser()
    }
  }, [])

  return <>{children}</>
}
