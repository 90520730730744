import { useEffect, useState, type FC, type PropsWithChildren } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useCorrectoApi } from '../../contexts'
import { isInExperiment } from '../../utils/amplitude'
import OnboardingNew from '../../OnboardingNew/Onboarding.new'
import Onboarding from '../../Onboarding/Onboarding'

export const PublicRoute: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const correctoApi = useCorrectoApi()
  const [hasNewOnboardingFlag, setHasNewOnboardingFlag] = useState(false)

  useEffect(() => {
    const fetchHasNewOnboardingFlag = async () => {
      const flag = await isInExperiment('new-onboarding')
      setHasNewOnboardingFlag(flag)
    }
    fetchHasNewOnboardingFlag()
  }, [])

  if (correctoApi.isAuthenticated()) {
    if (location.pathname === '/onboarding') {
      ;(async () => {
        const user = await correctoApi.getUser()
        correctoApi.sendTokenToExtension(user.body)
      })()

      if (hasNewOnboardingFlag) {
        return <OnboardingNew />
      }

      // Todo: this is for dev purposes, toggle these lines as you need:
      // return <OnboardingNew />;
      return <Onboarding />
    }

    const { next }: { next: string } = location.state || {
      next: '/projects',
    }

    return <Navigate to={next} />
  }

  if (location.pathname === '/onboarding') {
    return <Navigate to="/sign-up?extension=true&onboarding=true" />
  }

  return <>{children}</>
}
