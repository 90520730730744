import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'ui'
import { MetricsCategory, MetricsName, trackEvent } from '../../utils/amplitude'
import Step3Button from './Step3Button'

const ONBOARDING_MESSAGE = 'setOnboarding'

const Step3: React.FC = () => {
  const { t } = useTranslation()
  const extensionId = localStorage.getItem('extensionId')

  const handleButtonClick = (url: string, metricName: MetricsName) => {
    extensionId &&
      chrome.runtime.sendMessage(extensionId, { action: ONBOARDING_MESSAGE })
    window.open(url, '_blank', 'noopener')
    trackEvent(MetricsCategory.GrowthInteracted, { name: metricName })
  }

  return (
    <div className="flex flex-col items-center justify-center mb-8">
      <h2 className="text-[40px] font-semibold text-blue-correcto-800 mb-20">
        {t('OnboardingStep3Title')}
      </h2>
      <div className="flex gap-14">
        <Step3Button
          imgSrc="/assets/onboardingGmail.svg"
          altText="gmail"
          text={t('OnboardingGmail')}
          onClick={() =>
            handleButtonClick(
              'https://mail.google.com/mail/u/0/#inbox?compose=new',
              MetricsName.GmailClicked,
            )
          }
        />
        <Step3Button
          imgSrc="/assets/onboardingWhatsapp.svg"
          altText="whatsapp"
          text={t('OnboardingWhatsapp')}
          onClick={() =>
            handleButtonClick(
              'https://web.whatsapp.com/',
              MetricsName.WhatsappClicked,
            )
          }
        />
        <Step3Button
          imgSrc="/assets/onboardingGdocs.svg"
          altText="gdocs"
          text={t('OnboardingGDocs')}
          onClick={() =>
            handleButtonClick(
              'https://docs.google.com/document/d/createdocument/create',
              MetricsName.GDocsClicked,
            )
          }
        />
      </div>
      <p className="text-xs font-light mt-8 inline-flex items-center gap-3">
        <Icon icon="security" size="md" />
        {t('OnboardingData')}
      </p>
    </div>
  )
}

export default Step3
