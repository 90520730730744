import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationButtons from '../Onboarding/Components/Navigationbuttons';
// import { MetricsCategory, MetricsName, trackEvent } from '../utils/amplitude'
import { Footer, Header, Step1, Step2, Step3, StepIndicator } from '../Onboarding/Components';

const steps = [
  { component: Step1, key: 1 },
  { component: Step2, key: 2 },
  { component: Step3, key: 3 }
];

const OnboardingNew: React.FC = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const userUuid = localStorage.getItem('userUuid');

  // const getStep = async () => {
  //   const response = await fetch(`/api/onboarding/${userUuid}`)
  //   const data = await response.json()
  //   return data.step
  // }

  useEffect(() => {
    // trackEvent(MetricsCategory.GrowthInteracted, {
    //   name: MetricsName.OnboardingViewed,
    //   location: window.location.hostname,
    //   step,
    // })
  }, [step, userUuid]);

  const handleNext = () => {
    if (step < steps.length) {
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const CurrentStep = steps[step - 1]?.component;

  if (!CurrentStep) {
    return null;
  }

  return (
    <div className="bg-neutral-100 h-screen w-full flex flex-col px-[116px] py-8">
      <img src="/assets/pinExtension.svg" alt="pin extension" className="absolute top-4 right-[111px]" />
      <Header />
      <div className="flex flex-col items-center justify-center flex-grow">
        <CurrentStep />
        <StepIndicator currentStep={step} totalSteps={steps.length} onStepClick={setStep} />
        <NavigationButtons
          currentStep={step}
          totalSteps={steps.length}
          onNext={handleNext}
          onPrevious={handlePrevious}
          t={t}
        />
      </div>
      <Footer t={t} />
    </div>
  );
};

export default OnboardingNew;
